import React, { useState } from "react";
import Container from "../../components/chat-channel/Container";
import Layout from "../../components/chat-channel/Layout";
import TitleAndMetaTags from "../../components/common/TitleAndHeader";
import { RequestForm } from "../../components/form";
import { SearchIcon, PlusIcon } from "../../components/common/Icons";
import RightImageWithContentFeature from "../../components/chat-channel/RightImageWithContent";

const section_one = require("../../assets/images/trigger/trigger_sec_2.png");
const apps = require("../../assets/images/icons-web/bamboo.svg");
const triggers = require("../../assets/automate/triggers_icon.svg");
const actions = require("../../assets/automate/actions_icon.svg");

export default function MarketplaceAppSelection() {
  return (
    <>
      <TitleAndMetaTags
        title="Free Workflow Automation Pricing | Workativ Automate"
        description="Learn more about workativ automate's pricing plans. Free workflow automation software."
        keywords={[
          "IT Helpdesk Chatbot",
          "Automate Chatbot",
          "Service Desk Chatbot",
        ]}
      />
      <Container>
        <div className=" automate_marketplace automate_product">
          <Layout
            backgroundColor={"automate_marketplace"}
            logoFor="AUTOMATE"
            product="AUTOMATE"
          >
            {/* Marketplace Header */}
            <MarkeplaceApps />

            {/* marketpalce Cards */}
            <Cards />
            <TriggersAndActions />
            <WhyAutomate />
            <div className="automate_homepage">
              <RequestForm
                product="automate"
                content="We need your feedback, inputs, and experience to help us build a great product!"
                AutomateCTA
                automateContent
                autoamteButton
                automateName
                isFooterForm={true}
              />
            </div>
          </Layout>
        </div>
      </Container>
    </>
  );
}

function Cards() {
  return (
    <section className="am_cards_wrapper">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="autoamte_apps_header_right">
              <div className="search_marketpalce">
                <input
                  className="search_click"
                  type="text"
                  id="myInput"
                  placeholder="Search Workflows"
                  title="Type in a name"
                  // onChange={e => setSearch(e.target.value)}
                />
                <span className="search_icon">
                  <SearchIcon />
                </span>
              </div>
            </div>
            <h2>Create BambooHR Workflows in Minutes</h2>
          </div>
          <div className="am_cards_column d-flex">
            <div className="am_cards_column_left col-md-6 col-lg-6 col-12">
              <div className="am_cards_container">
                <div className="am_cards_img_wrapper">
                  <div className="am_cards_img">
                    <span>
                      <img src={apps}></img>
                    </span>
                    <span className="space_apps">
                      <PlusIcon />
                    </span>
                    <span>
                      <img src={outlook}></img>
                    </span>
                  </div>
                  <div className="am_cards_button">
                    <button>Try Now</button>
                  </div>
                </div>
                <h5>
                Add Employee in BambooHR and Send Email to HR in Outlook
                </h5>
              </div>
                {/* mobile */}
                <div className="mobile_cards">
                <div className="mobile_cards_width">
              <div className="am_cards_container">
                <div className="am_cards_img_wrapper">
                  <div className="am_cards_img">
                    <span>
                      <img src={apps}></img>
                    </span>
                    <span className="space_apps">
                      <PlusIcon />
                    </span>
                    <span>
                      <img src={outlook}></img>
                    </span>
                  </div>
                </div>
                <h5>
                ddddddsdfdsasdds
                </h5>
                <div className="am_cards_button">
                    <button>Try Now</button>
                  </div>
                </div>
                </div>
                </div>
            </div>
            <div className="am_cards_column_left col-md-6 col-lg-6 col-12">
              <div className="am_cards_container">
                <div className="am_cards_img_wrapper">
                  <div className="am_cards_img">
                    <span>
                      <img src={apps}></img>
                    </span>
                    <span className="space_apps">
                      <PlusIcon />
                    </span>
                    <span>
                      <img src={outlook}></img>
                    </span>
                  </div>
                  <div className="am_cards_button">
                    <button>Try Now</button>
                  </div>
                </div>
                <h5>
                Request Time Off in BambooHR and Send Email Approval to Manager in Oulook
                </h5>
              </div>
            </div>
            <div className="am_cards_column_left col-md-6 col-lg-6 col-12">
              <div className="am_cards_container">
                <div className="am_cards_img_wrapper">
                  <div className="am_cards_img">
                    <span>
                      <img src={apps}></img>
                    </span>
                    <span className="space_apps">
                      <PlusIcon />
                    </span>
                    <span>
                      <img src={servicenow}></img>
                    </span>
                  </div>
                  {/* <div className="am_cards_button">
                    <button className="soon_btn_bg">Coming Soon</button>
                  </div> */}
                  <div className="am_cards_button">
                    <button>Try Now</button>
                  </div>
                </div>
                <h5>
                Add Employee Dependent in BambooHR and Create Ticket in Freshservice in Resolved state.
                </h5>
              </div>
            </div>
            <div className="am_cards_column_left col-md-6 col-lg-6 col-12">
              <div className="am_cards_container">
                <div className="am_cards_img_wrapper">
                  <div className="am_cards_img">
                    <span>
                      <img src={apps}></img>
                    </span>
                    <span className="space_apps">
                      <PlusIcon />
                    </span>
                    <span>
                      <img src={ad}></img>
                    </span>
                  </div>
                  {/* <div className="am_cards_button">
                    <button className="soon_btn_bg">Coming Soon</button>
                  </div> */}
                  <div className="am_cards_button">
                    <button>Try Now</button>
                  </div>
                </div>
                <h5>
                Add User In BambooHR and Add User in Azure Active Directory
                </h5>
              </div>
            </div>
            <div className="am_cards_column_left col-md-6 col-lg-6 col-12">
              <div className="am_cards_container">
                <div className="am_cards_img_wrapper">
                  <div className="am_cards_img">
                    <span>
                      <img src={apps}></img>
                    </span>
                    <span className="space_apps">
                      <PlusIcon />
                    </span>
                    <span>
                      <img src={gmail}></img>
                    </span>
                  </div>
                  {/* <div className="am_cards_button">
                    <button className="soon_btn_bg">Coming Soon</button>
                  </div> */}
                  <div className="am_cards_button">
                    <button>Try Now</button>
                  </div>
                </div>
                <h5>
                Add Employee in BambooHR ad Send Email to HR in Gmail
                </h5>
              </div>
            </div>
            <div className="am_cards_column_left col-md-6 col-lg-6 col-12">
              <div className="am_cards_container">
                <div className="am_cards_img_wrapper">
                  <div className="am_cards_img">
                    <span>
                      <img src={apps}></img>
                    </span>
                    <span className="space_apps">
                      <PlusIcon />
                    </span>
                    <span>
                      <img src={outlook}></img>
                    </span>
                    <span className="space_apps">
                      <PlusIcon />
                    </span>
                    <span>
                      <img src={ad}></img>
                    </span>
                  </div>
                  {/* <div className="am_cards_button">
                    <button className="soon_btn_bg">Coming Soon</button>
                  </div> */}
                  <div className="am_cards_button">
                    <button>Try Now</button>
                  </div>
                </div>
                <h5>
                Add User In BambooHR and Add User in Azure Active Directory and Send Email to HR in Outlook
                </h5>
              </div>
            </div>
            <div className="am_cards_column_left col-md-6 col-lg-6 col-12">
              <div className="am_cards_container">
                <div className="am_cards_img_wrapper">
                  <div className="am_cards_img">
                    <span>
                      <img src={apps}></img>
                    </span>
                    <span className="space_apps">
                      <PlusIcon />
                    </span>
                    <span>
                      <img src={servicenow}></img>
                    </span>
                  </div>
                  <div className="am_cards_button">
                    <button className="soon_btn_bg">Coming Soon</button>
                  </div>
                
                </div>
                <h5>
                Add Employee Dependent in BambooHR and Create Ticket in ServiceNow in Resolved state.
                </h5>
              </div>
            </div>
            <div className="am_cards_column_left col-md-6 col-lg-6 col-12">
              <div className="am_cards_container">
                <div className="am_cards_img_wrapper">
                  <div className="am_cards_img">
                    <span>
                      <img src={apps}></img>
                    </span>
                    <span className="space_apps">
                      <PlusIcon />
                    </span>
                    <span>
                      <img src={ad}></img>
                    </span>
                  </div>
                  <div className="am_cards_button">
                    <button className="soon_btn_bg">Coming Soon</button>
                  </div>
                
                </div>
                <h5>
                Remove User In BambooHR and remove User in Azure AD
                </h5>
              </div>
            </div>

            <div className="show_morw_option">
              <span>Show More</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

function MarkeplaceApps() {
  return (
    <section className="marketplace_autoamte_apps automate_marketplace">
      <div className="container">
        <div className="row">
          <div className="col-12 autoamte_apps_header">
            <h1>BambooHR Integration and Workflows</h1>
            <p>Integrate BambooHR with your favorite apps</p>
          </div>
          <div className="market_apps_conainer">
          <div className="col-md-6 col-lg-6 col-12">
            <div className="autoamte_apps_header_left">
              <div className="apps_header_left_container">
                <div className="autoamte_apps_selection">
                  <img src={apps} alt="apps" />
                  <h6>BambooHR</h6>
                </div>
                <p>
                BambooHR is a cloud based HR software solution enabling companies of small and medium size to deliver great employee experience. It help companies in hiring, payroll, onboarding, process and employee management.
                </p>
              </div>
            </div>
          </div>
          <div className="center_connection">
            <span>
              <PlusIcon />
            </span>
          </div>
          <div className="col-md-6 col-lg-6 col-12">
            <div className="autoamte_apps_header_right ">
              <div className="search_marketpalce">
                <input
                  className="search_click"
                  type="text"
                  id="myInput"
                  placeholder="Search from 50+ Apps"
                  title="Type in a name"
                  // onChange={e => setSearch(e.target.value)}
                />
                <span className="search_icon">
                  <SearchIcon />
                </span>
                <div className="search_apps_wrapper w-100"></div>
                <div className="empty_space_clear" />
              </div>
              <div className="search_apps_box">
                {integrationsPage.map((apps) => (
                  <div className="apps_list">
                    <img src={apps.image} alt={apps.header} />
                    <h6>{apps.header}</h6>
                  </div>
                ))}
              </div>
            </div>
          </div>
          </div>
          <div className="content_center_automate">
            <FormAutoamte />
            <p>
            Explore 100+ Integrations. 
              <span>
                <a href="javascript:void(0);">Try Beta</a>
              </span>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

function TriggersAndActions() {
  return (
    <section className="am_cards_wrapper actions_wrapper">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h3>Things You Can Do With BambooHR</h3>
          </div>
          <div className="am_cards_column d-flex">
            {triggersContent.map((data) => (
              <>
                <div className="am_cards_column_left col-md-6 col-lg-6 col-12">
                  <div className="actions_wrapper_container">
                    <h4>
                      <span>
                        <img src={data.image} alt={data.header}></img>
                      </span>
                      {data.header}
                    </h4>
                    {/* Desktop mode */}
                    {data.useCase.map((data) => (
                      <>
                        <div className="am_cards_container">
                          <div className="am_cards_img">
                            <span>
                              <img src={data.image} alt={data.header}></img>
                            </span>
                          </div>
                          <div className="am_cards_content">
                            {data.comingSoon && (
                              <div className="am_cards_button">
                                <button className="soon_btn_bg">
                                  {" "}
                                  Coming Soon
                                </button>
                              </div>
                            )}
                            <h5>{data.header}</h5>
                            <h6>{data.content}</h6>
                          </div>
                        </div>
                        {/* Responsive mode */}
                        <div className="am_cards_container _responsive_mode">
                          <div className="am_cards_img">
                            <span>
                              <img src={data.image} alt={data.header}></img>
                            </span>
                           
                          </div>
                          <div className="am_cards_content">
                            <h5>{data.header}</h5>
                            <h6>{data.content}</h6>
                            {data.comingSoon && (
                              <div className="am_cards_button">
                                <button className="soon_btn_bg">
                                  {" "}
                                  Coming Soon
                                </button>
                              </div>
                            )}
                          </div>
                         
                        </div>
                      </>
                    ))}
                  </div>
                </div>
              </>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

function WhyAutomate() {
  return (
    <section className="why_autoamte_wrapper">
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-lg-6 col-12">
            <h3>Why Workativ Automate?</h3>
            <p>
              Most workflow products are complex, expensive, and bloated with
              things that you don’t need. Workativ Automate is purpose built
              iPaaS to help you connect and automate your workplace tasks. It’s
              easy to use yet powerful, and affordable to all.
            </p>
          </div>
          <div className="col-md-6 col-lg-6 col-12">
            <div className="three_steps_wrapper">
              <div className="three_steps_left">
                <img src={require("../../assets/automate/simple_icon.png")} />
              </div>
              <div className="three_steps_right">
                <h5>Simple</h5>
                <p>Use no-code canvas to build single or multistep workflows</p>
              </div>
            </div>
            <div className="three_steps_wrapper">
              <div className="three_steps_left">
                <img src={require("../../assets/automate/powerful_icon.png")} />
              </div>
              <div className="three_steps_right">
                <h5>Powerful </h5>
                <p>
                  Set triggers, conditions, webhooks, format data into your
                  workflows
                </p>
              </div>
            </div>
            <div className="three_steps_wrapper">
              <div className="three_steps_left">
                <img src={require("../../assets/automate/secure_icon.png")} />
              </div>
              <div className="three_steps_right">
                <h5>Secure</h5>
                <p>
                  Data encryption at transit and rest, audit logs and access
                  controls 
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
const box = require("../../assets/images/icons-web/box_1.svg");
const deputy = require("../../assets/images/icons-web/deputy.svg");
const dropbox = require("../../assets/images/icons-web/dropbox.svg");
const freshservice = require("../../assets/images/icons-web/freshservice.svg");
const gdrive = require("../../assets/images/icons-web/gdrive.svg");
const gmail = require("../../assets/images/icons-web/gmail.svg");
const jira = require("../../assets/images/icons-web/jira.svg");
const jumpcloud = require("../../assets/images/icons-web/jumpcloud.svg");
const ad = require("../../assets/images/icons-web/zure.svg");
const servicenow = require("../../assets/images/icons-web/servicenow.svg");
const outlook = require("../../assets/images/icons-web/outlook.svg");

const integrationsPage = [
  { image: box, alt: "box", header: "Box" },
  {
    image: deputy,
    alt: "deputy",
    header: "Deputy",
  },
  {
    image: dropbox,
    alt: "Dropbox",
    header: "Dropbox",
  },
  {
    image: freshservice,
    alt: "freshservice",
    header: "Freshservice",
  },
  {
    image: gdrive,
    alt: "Google Drive",
    header: "Google Drive",
  },
  {
    image: jira,
    alt: "Jira",
    header: "Jira",
  },
  {
    image: jumpcloud,
    alt: "JumpCloud",
    header: "JumpCloud",
  },
  {
    image: ad,
    alt: "Microsoft Azure AD",
    header: "Microsoft Azure AD",
  },
  {
    image: servicenow,
    alt: "ServiceNow",
    header: "ServiceNow",
  },
  {
    image: outlook,
    alt: "outlook",
    header: "Outlook",
  },
];

const triggersContent = [
  {
    type: "Trigger",
    image: triggers,
    header: "Trigger",
    useCase: [
      {
        image: apps,
        header: "New Employee",
        content: "Triggers when a new employee is created.",
      },
      {
        image: apps,
        header: "New Time off Request",
        content: "Triggers when a new time off request is created.",
      },
      {
        image: apps,
        header: "Employee Updated.",
        content: "Triggers when an existing employee is updated.",
        comingSoon:true,
      },
    ],
  },
  {
    type: "Actions",
    image: actions,
    header: "Actions",
    useCase: [
      {
        image: apps,
        header: "Add employee dependent",
        content: "Add employee dependent in BambooHR",
      },
      {
        image: apps,
        header: "Get all time off policies",
        content: "Get all time off policies from BambooHR",
      },
      {
        image: apps,
        header: "Get employee bonus details",
        content: "Get employee bonus details from BambooHR",
      },
      {
        image: apps,
        header: "Get holiday list",
        content: "Get holiday list from BambooHR",
      },
      {
        image: apps,
        header: "Request time off",
        content: "Request time off in BambooHR",
        comingSoon:true,
      },
      {
        image: apps,
        header: "Get benefit plan details",
        content: "Get benefit plan details from BambooHR",
        comingSoon:true,
      },
      {
        image: apps,
        header: "Get employee details",
        content: "Get employee details in BambooHR",
        comingSoon:true,
      },
    ],
  },
];

function FormAutoamte() {
  return(
      <div className="form_automate">
          <p>This integration will be available soon.</p>
          <input type="text" placeholder="Enter your email to join the waiting list."/>
          <button>Notify Me</button>
      </div>
  )
}